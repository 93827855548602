import React, { useReducer } from "react";
import ShopContext from "./ShopContext";
import { shopReducer, ADD_PRODUCT, REMOVE_PRODUCT, CLEAR_CART } from "./reducers";

function GlobalState(props) {
  const [cartState, dispatch] = useReducer(shopReducer, { cart: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem("cart")) ?? [] : [] });

  const addProductToCart = product => {
    dispatch({ type: ADD_PRODUCT, product: product });
  };

  const removeProductFromCart = productId => {
    dispatch({ type: REMOVE_PRODUCT, productId: productId });
  };

  const clearCart = () => {
    dispatch({type: CLEAR_CART})
  }

  return (
    <ShopContext.Provider
      value={{
        cart: cartState.cart,
        addProductToCart: addProductToCart,
        removeProductFromCart: removeProductFromCart,
        clearCart: clearCart
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
}

export default GlobalState;
