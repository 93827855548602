import React from "react";

export default React.createContext({
    cart: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem("cart")) ?? [] : [],


  addProductToCart: product => {},
  removeProductFromCart: productId => {},
  clearCart: () => {}
});
