/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'typeface-roboto'
import GlobalState from './src/context/GlobalState';

import './src/styles/global.css';

import React from 'react'


export const wrapRootElement = ({ element }) => (
    <GlobalState>{element}</GlobalState>
)
  